import localStorageConstants from '../config/localStorageConstants';
import config_src from '../config/config';

import store from '../redux/store';
import { setEmpty, setUserIdentifier as setUserIdentifierRTK } from '../redux/slices/userSlice';
// import * as Sentry from '@sentry/react';

export const token_name = 'jwtToken';

export const APP_VERSION = config_src.version;
export const removeUserRTK = () => {
    store.dispatch(setEmpty());
};


const {
    user_identifier,
    appliedCoupon,
    customer_id,
    address_id,
    excludeVAT,
    isCompany,
    user_express, user_express_data,
    country_tax, country_id,
} = localStorageConstants;

export const setToken = (token) => {
    const currentTime = new Date();
    const thirtyMinutesFromNow = new Date(currentTime.getTime() + 360 * 60000);

    // console.log("SESSION SET TOKEN: setting token at date:", new Date());

    localStorage.setItem(token_name, JSON.stringify({ "token": token, "expiresAt": thirtyMinutesFromNow.toString() }))// make up your own token
    window.dispatchEvent(new Event('storage')); // Trigger storage event manually

    if (getUserExpress()) {
        localStorage.removeItem(user_express);
        if (localStorage.getItem(user_express_data)) {
            localStorage.removeItem(user_express_data);
        }
    }
};
export const setUserIdentifier = (userIdentifier) => {
		// console.log("userIdentifier", userIdentifier);
    store.dispatch(setUserIdentifierRTK(userIdentifier));
    localStorage.setItem(user_identifier, userIdentifier);
};
export const setExpress = (customerId) => {
    localStorage.setItem(user_express, customerId);
};
export const setExpressData = (data) => {
    const currentTime = new Date();
    const thirtyMinutesFromNow = new Date(currentTime.getTime() + 30 * 60000);
    localStorage.setItem(user_express_data, JSON.stringify({
        'data': data,
        'expiresAt': thirtyMinutesFromNow.toString(),
    }));
};

// Outdated method => don't use it in the future.
export const getCountrySelected = () => {
    const countrySelected = localStorage.getItem(country_id);
    if (countrySelected) {
        return countrySelected;
    }
    return null;
};

// Outdated method => don't use it in the future.
export const getCountryTax = () => {
    const countryTax = localStorage.getItem(country_tax);
    if (countryTax) {
        return countryTax;
    }
    return null;
	
};

export const getUserIdentifier = () => {
  const state = store.getState();
  
  const userIdentifier = state.user.user_identifier;
  const userIdentifierFromLocalStorage = localStorage.getItem(user_identifier);
  return userIdentifier || userIdentifierFromLocalStorage;
};

export const fetchToken = () => {
	
    // Sentry.captureMessage('in fetch token');
    checkExpressData();
		
	// const state = store.getState();
		// const tokenFromRedux = state.user.token;
		
    const token_cookie = JSON.parse(localStorage.getItem(token_name));

		// TODO: Cristi, get the token from redux here.
	
		// if (tokenFromRedux && !token_cookie) {
		// 	console.log("entering into tokenFromRedux");
		// 	token_cookie = tokenFromRedux;
		// }
	
    //  Sentry.captureMessage('token_name ' + token_name);
    if (token_cookie) {
       
        const storedDate = new Date(token_cookie.expiresAt);
        
        //  Sentry.captureMessage('storedate ' + storedDate);
        const currentTime = new Date();
      
        //   Sentry.captureMessage('currentTime ' + currentTime);
        if (storedDate.getTime() < currentTime.getTime()) {
            
            removeToken();
            removeUserRTK();
            return null;
        }
		
        return token_cookie.token;
    }
    return null;
};

export const getUserExpress = () => {
    const userExpress = localStorage.getItem(user_express);
    if (userExpress) {
        return userExpress;
    }
    return null;
	
};

export const getExpressData = () => {
    const expressData = JSON.parse(localStorage.getItem(user_express_data));
    if (expressData) {
        removeExpress();
        return expressData.data;
    }
    return null;
};

export const checkExpressData = () => {
   
    const expressData = JSON.parse(localStorage.getItem(user_express_data));
    if (expressData) {
      
        const storedDateE = new Date(expressData.expiresAt);
   
        const currentTimeE = new Date();
        
        if (storedDateE.getTime() < currentTimeE.getTime()) {

            localStorage.removeItem(user_express_data);
        }
    }
};

export const removeExpress = () => {
    localStorage.removeItem(user_identifier);
    localStorage.removeItem(user_express);
    if (localStorage.getItem(user_express_data)) {
        localStorage.removeItem(user_express_data);
    }
};

export const getTheliaToken = () => `TOKEN ${config_src.token}`;

export const getBearerToken = () => `Bearer ${fetchToken()}`;


export const removeToken = () => {
    localStorage.removeItem(token_name);
    localStorage.removeItem(user_identifier);
    localStorage.removeItem(customer_id);
    localStorage.removeItem(address_id);
    localStorage.removeItem(appliedCoupon);
    localStorage.setItem(isCompany, false);
    localStorage.setItem(excludeVAT, false);
};
