import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotFound from './pages/other/NotFound';
import useFetchData from './hooks/useFetchDataNestedRouter';
import { useWhyDidYouUpdate } from 'ahooks';

// Lazy-loaded components with explicit naming for better debugging
const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'))
const Product = lazy(() => import('./pages/shop-product/Product'))
const Content = lazy(() => import('./pages/other/Content'))

/**
 * NestedRouter component handles dynamic routing based on content type
 * after resolving a URL path through the rewriting service.
 */
function NestedRouter() {
	const location = useLocation();
	const { t } = useTranslation();
	// Add these state variables
	const [brandId, setBrandId] = useState(null);
	const [categoryId, setCategoryId] = useState(null);
	
	
	const searchString = useMemo(() => {
		const encodedPathString = location.pathname.substring(1);
		return decodeURIComponent(encodedPathString);
	}, [location.pathname]);
	// Scroll to top on location change
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	
	useWhyDidYouUpdate("NEST", { searchString });
	
	// Fetch data based on URL
	const {
		rewritingResult,
		productResult,
		categoryResult,
		contentResult,
		brandResult,
		pseResult,
		isProduct,
		isCategory,
		isContent,
		isBrand,
		isPse
	} = useFetchData(searchString);
	
	useEffect(() => {
		if (isContent && contentResult.isSuccess && contentResult.data?.postscriptum) {
			try {
				const data = contentResult.data;
				const trimmedValue = data.postscriptum.trim();
				
				const isValidJSON = str => {
					try {
						JSON.parse(str);
						return true;
					} catch (e) {
						return false;
					}
				};
				
				if (isValidJSON(trimmedValue)) {
					const postscriptumObj = JSON.parse(trimmedValue);
					setBrandId(postscriptumObj?.brand_id);
					setCategoryId(postscriptumObj?.category_id);
				}
			} catch (error) {
				console.error("Error parsing postscriptum: ", error);
			}
		}
	}, [isContent, contentResult.isSuccess, contentResult.data]);
	
	
	const {PUBLIC_URL} = process.env;
	
	// Memoize loading component to prevent unnecessary re-renders
	const loadingComponent = useMemo(() => (
		<div className="loading-message" data-testid="loading-indicator">
			{t('Please wait...')}
		</div>
	), [t]);
	
	// Handle loading and error states
	if (rewritingResult.isLoading) {
		return loadingComponent;
	}
	
	if (rewritingResult.isError) {
		console.error('Rewriting error:', rewritingResult.error);
		return <NotFound reason="rewriting-failed" />;
	}
	
	/**
	 * Render functions for different content types
	 * These are defined inside the component to access context
	 * but outside the return statement for clarity
	 */
		
	// Product route renderer
	const renderProductRoute = () => {
			const { isError, isSuccess, data } = productResult;
			if (!isSuccess) {
				const { isLoading } = productResult;
				if (isLoading) {
					return loadingComponent;
				}
				if (isError) {
					return <NotFound reason="product-fetch-failed" />;
				}
				return null;
			}
			return <Product product={data} />;
		};
	
	// Category route renderer
	const renderCategoryRoute = () => {
		const { isSuccess, isLoading, isError, error, data } = categoryResult;
		
		if (isLoading) {
			return loadingComponent;
		}
		
		if (isError || !isSuccess || !data) {
			console.error('Category fetch error:', error);
			return <NotFound reason="category-fetch-failed" />;
		}
		
		return (
			<ShopGridStandard
				additionalProp={data}
				brandId={null}
				categoryId={data.ID}
				title={data.TITLE}
				
			/>
		);
	};
	
	// Brand route renderer
	const renderBrandRoute = () => {
		const { isLoading, isError, error, data } = brandResult;
		
		if (isLoading) {
			return loadingComponent;
		}
		
		if (isError || !data) {
			
			
			console.error('Brand fetch error:', error);
			return <NotFound reason="brand-fetch-failed" />;
		}
		
		return (
			<ShopGridStandard
				additionalProp={data}
				brandId={data.brand_id}
				categoryId={null}
				title={data.brand_title}
			/>
		);
	};
	
	// Content route renderer
// Content route renderer
	const renderContentRoute = () => {
		const { isSuccess, isLoading, isError, error, data } = contentResult;
		
		if (isLoading) {
			return loadingComponent;
		}
		
		if (isError || !isSuccess || !data) {
			console.error('Content fetch error:', error);
			return <NotFound reason="content-fetch-failed" />;
		}
		
		// Use state variables instead of data properties
		if (brandId && categoryId) {
			// console.log("Showing BRAND AND CATEGORY");
			return (
				<ShopGridStandard
					additionalProp={data}
					brandId={brandId}
					categoryId={categoryId}
					title={data.title}
				/>
			);
		}
		
		return <Content contentData={data} />;
	};	// Determine which route to render based on content type
	const getRouteElement = () => {
		if (isProduct || isPse) {
			return renderProductRoute();
		}
		
		if (isCategory) {
			return renderCategoryRoute();
		}
		
		if (isBrand) {
			return renderBrandRoute();
		}
		
		if (isContent) {
			return renderContentRoute();
		}
		
		// No content type match
		return <NotFound reason="content-type-not-found" />;
	};
	return (
		<Suspense fallback={loadingComponent}>
			<Routes>
				{/* Use a single Route component with the correct element based on content type */}
				{rewritingResult.isSuccess && (
					<Route
						path={`${PUBLIC_URL}/${searchString}/*`}
						element={getRouteElement()}
					/>
				)}
			</Routes>
		</Suspense>
	);
}

export default React.memo(NestedRouter);
