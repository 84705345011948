import { useEffect } from "react";

const GoogleBadge = ({ merchantId }) => {
    useEffect(() => {
        window.renderBadge = function () {
            const ratingBadgeContainer = document.createElement("div");
            document.body.appendChild(ratingBadgeContainer);

            if (window.gapi) {
                window.gapi.load("ratingbadge", function () {
                    window.gapi.ratingbadge.render(ratingBadgeContainer, {
                        merchant_id: merchantId,
                        position: "BOTTOM_LEFT"
                    });
                });
            }
        };

        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/platform.js?onload=renderBadge";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default GoogleBadge;