import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import config from '../../config/config.js';
import { getTheliaToken } from '../../helpers/session.js';
import { getCustomerId } from '../api/cart.js';
import { t } from 'i18next';
import { defaultConfig } from './fastApiService.js';

export const ioonsApi = createApi({
		...defaultConfig,
    reducerPath: 'IoonsApi',
    baseQuery: fetchBaseQuery(
        {
            baseUrl: `${config.apiUrl}/api/`, prepareHeaders: (headers) => {
                headers.set('Access-Control-Allow-Origin', '*');
                headers.set('Content-Type', 'application/json');
                headers.set('Accept', 'application/json');
                headers.set('Authorization', getTheliaToken());
                return headers;
            },
        }),
		endpoints: (builder) => ({
        getConfig: builder.query({
            query: () => 'ConfigApi',
            // keepUnusedDataFor: 1800,
            // keepUnusedDataFor: 0,
        }),
        getRewritingUrl: builder.query({
            query: ({ view_locale, view_url }) => ({
                url: `rewriting`,
                params: { view_locale, view_url },
            }),
        }),
        getIndividualCategories: builder.query({
            query: (data) => ({ url: `categories/${data}` }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue.categoryItems[0],
        }),
        getIndividualProduct: builder.query({
            query: (data) => ({ url: `products/${data.productId}?document=1` + `${data.countryId ? `&country_id=${data.countryId}` : ''}` }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        }),
        // This endpoint from below is not used anymore - deprecated
        // getIndividualPSE: builder.query({
        //     query: ({ PRODUCT_SALE_ELEMENT }) => ({ url: `pse/${PRODUCT_SALE_ELEMENT}` }),
        //     transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        // }),
        getPSE: builder.query({
            query: ({ PRODUCT_SALE_ELEMENT, countryId }) => ({
                url: `get_pse/${PRODUCT_SALE_ELEMENT}` + (countryId ? `?country_id=${countryId}` : '')
            }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        }),
        getExternalOrder: builder.query({
            query: ({ productID, langCode }) => ({ url: `ExternalOrderApi/features/product/${productID}/${langCode}` }),
        }),
        getExternalApiOrder: builder.query({
            query: (order_id) => ({ url: `ExternalOrderApi/order/${order_id}?customer=${getCustomerId()}` }),
            transformResponse: (response) => response.OrderItems[0],
        }),
        getCoupon: builder.mutation({
            query: ({ coupon_code, cart_id, customer_id }) => ({
                url: 'ExternalOrderApi/insertCoupon/',
                method: 'POST',
                body: { coupon_code, cart_id, customer_id },
            }),
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: `changePassword`,
                method: 'POST',
                body: data,
            }),
        }),
				getOrdersFromCustomer: builder.query({
					query: (userId) => ({
						url: `ExternalOrderApi/order/?customer=${userId}&limit=999`
					}),
					transformResponse: (response) => response.OrderItems,
				}),
        getUser: builder.query({
            query: (email) => `ExternalOrderApi/customer/?email=${encodeURIComponent(email)}`,
            transformResponse: (response) => {
							const user = response.CustomerItems[0];
							
							return {
								ID: user.ID,
								ADDRESS_ID: user.ADDRESS_ID,
								email: user.EMAIL,
								firstName: user.FIRSTNAME,
								lastName: user.LASTNAME,
								ref: user.REF,
								id: user.ID,
								company: user.COMPANY,
								title: user.TITLE === 1 ? t('Mr')
									: user.TITLE === 2 ? t('Miss')
										: user.TITLE === 5 ? t('Association')
											: t('Company'),
								title_id: user.TITLE,
								address: [{
									address_first_name: user.ADDRESS_FIRSTNAME,
									address_last_name: user.ADDRESS_LASTNAME,
									complete_address: `${user.ADDRESS1} ${user.ADDRESS2} ${user.ADDRESS3}`,
									address1: user.ADDRESS1,
									address2: user.ADDRESS2,
									city: user.CITY,
									country: user.COUNTRY_TITLE,
									state: user.STATE_TITLE,
									cellphone: user.CELLPHONE,
									zipcode: user.ZIPCODE,
									address_id: user.ADDRESS_ID,
									state_id: user.STATE_ID,
									state_title: user.STATE_TITLE,
									country_id: user.COUNTRY_ID,
									phone: user.PHONE,
									title_id: user.ADDRESS_TITLE_ID
								}]
							}
            },
        }),
    }),
});
export const {
    useGetConfigQuery,
    useGetIndividualCategoriesQuery,
    useGetIndividualProductQuery,
    useGetExternalOrderQuery,
    useGetExternalApiOrderQuery,
    useGetCouponMutation,
    useUpdatePasswordMutation,
    // useGetIndividualPSEQuery,
    useGetPSEQuery,
    useGetUserQuery,
		useGetOrdersFromCustomerQuery
} = ioonsApi;
