import PropTypes from "prop-types";
import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import { useNavigate } from 'react-router-dom';

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass
}) => {
  const [emailValue, setEmailValue] = useState('');
	const navigate = useNavigate();
	
	const { t } = useTranslation();

  function handleSubmit(event) {
    event.preventDefault();
    const emailQueryParam = encodeURIComponent(emailValue);
    navigate("/newsletter?email="+emailQueryParam);
  }

  return (
    <div
      className={`footer-widget ${spaceBottomClass ? spaceBottomClass : ""} ${spaceLeftClass ? spaceLeftClass : ""}`}
    >
      <div className="subscribe-style">
        <div className="subscribe-form">
          <div className="footer-title">
            <h3>{t('Newsletter')}</h3>
          </div>
          <p>{t('Sign up to receive our latest news.')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mc-form">
              <div>
                <input
                    id="mc-form-email"
                    className="email"
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                    type="email"
                    placeholder={t('Your email address')}
                    required
                />
              </div>
              <div className="clear">
                <button className="button" type="submit">
                  {t('Subscribe')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string
};

export default FooterNewsletter;
