import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import config from '../../config/config.js';

function CountryDisplay() {
	// This component is just for testing on dev, to have a visibility on the values.
	
	// It will be not used in production ever.
	const selectedCountry = useSelector((state) => state.country?.selectedCountry);
	const { id, tax_title, title } = selectedCountry || {};
	
	const userRedux = useSelector(state => state.user?.userInfo);
	const tokenRedux = useSelector(state => state.user?.token);
	const loggedIn = useSelector(state => state.user?.userLoggedIn);
	const email = useSelector(state => state.user?.user_identifier);
	const profileName = useSelector(state => state.user?.profileName);
	const customerId = useSelector(state => state.user?.customer_id);
	const addressId = useSelector(state => state.user?.address_id);
	const version = useSelector(state => state.app.version);
	const lastLocation = useSelector(state => state.app.lastLocation);
	const headerSize = useSelector(state => state.header.size);
	const admin = useSelector(state => state.user.isAdmin);
	
	
	return (
		<div className={'banner-top-color'}>
			<Container>
				<Row className={'banner-top-color'}><Col className={"text-center"}>
					THIS IS YOUR local {config.name} ENVIRONMENT
				</Col></Row>
				<Row>
					<p>Selected Country: {selectedCountry ? `${title}` : 'None'}</p>
					{/*<p>Selected Country: {selectedCountry ? `${id}, ${title} ${tax_title}` : 'None'}</p>*/}
					{/*<p>Selected User: {userRedux ? JSON.stringify(userRedux) : 'NONE'}</p>*/}
					{/*<p>Selected Token: {tokenRedux ? JSON.stringify(tokenRedux) : "NONE"}</p>*/}
					{/*<Col>Logged In: {loggedIn ? 'YES' : 'NO'}</Col>*/}
					{/*<Col>Profile Name: {profileName || ''}</Col>*/}
					{/*<Col>email: {email || ''}</Col>*/}
					{/*<Col>version: {version || ''}</Col>*/}
					{/*<Col>customerId: {customerId || ''}</Col>*/}
					{/*<Col>addressId: {addressId || ''}</Col>*/}
					{/*<Col>Header Size: {headerSize}</Col>*/}
				</Row>
				<Row>
					<Col>
						{/*userRedux: {JSON.stringify(userRedux)}*/}
						{/*lastLocation: {JSON.stringify(lastLocation)}*/}
					</Col>
				</Row>
				<Row>
					<Col>
						{/*Admin: {admin ? 'YES' : 'NO'}*/}
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CountryDisplay;
