import {
	ADD_TO_CART,
	CHANGE_FULFILMENT,
	DECREASE_QUANTITY,
	UPDATE_QTY,
	DELETE_ALL_FROM_CART,
	DELETE_FROM_CART,
	UPDATE_CART_ITEM
} from '../actions/cartActions';

const initState = [];

const cartReducer = (state = initState, action) => {
	let cartItems = state;
	const product = action.payload;
	if (JSON.parse(localStorage.getItem('redux_localstorage_simple'))) {
		cartItems = JSON.parse(localStorage.getItem('redux_localstorage_simple')).cartData;
	}

	if (action.type === ADD_TO_CART && product.quantity) {
		const cartItem = cartItems.filter(item => item.PRODUCT_SALE_ELEMENT === product.PRODUCT_SALE_ELEMENT)[0];
		if (cartItem === undefined) {
			return [
				...cartItems,
				{
					...product,
					quantity: product.quantity,
					cartItemId: product.cartItemId,
					undeliverable: product.undeliverable || false
				},
			];
		}
		return cartItems.map(item =>
			item.PRODUCT_SALE_ELEMENT === product.PRODUCT_SALE_ELEMENT
				? {
					...item,
					quantity: product.quantity,
					undeliverable: product.undeliverable || false
				}
				: item,
		);
	}
	if (action.type === DECREASE_QUANTITY) {
		if (product.quantity === 1) {
			return cartItems.filter(item => item.cartItemId !== product.cartItemId);
		}
		return cartItems.map(item =>
			item.cartItemId === product.cartItemId
				? { ...item, quantity: product.quantityRemain }
				: item,
		);
	}
	if (action.type === UPDATE_QTY) {
		if (product.quantity <= 0) {
			return cartItems.filter(item => item.cartItemId !== product.cartItemId);
		}
		return cartItems.map(item =>
			item.cartItemId === product.cartItemId
				? { ...item, quantity: product.quantityRemain }
				: item,
		);
	}
	if (action.type === DELETE_FROM_CART) {
		return cartItems.filter(item => item.cartItemId !== product.cartItemId);
	}

	if (action.type === DELETE_ALL_FROM_CART) {
		return [];
	}

	if (action.type === CHANGE_FULFILMENT) {
		return cartItems.map(item =>
			item.cartItemId === product.cartItemId
				? { ...item, selectedFulfilmentCenter: product.selectedFulfilmentCenter }
				: item,
		);
	}

	if (action.type === UPDATE_CART_ITEM) {
		return cartItems.map(item =>
			item.cartItemId === action.payload.cartItemId
				? { ...item, undeliverable: action.payload.undeliverable || false }
				: item,
		);
	}

	return state;
};

export default cartReducer;
