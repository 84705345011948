// src/config/config.js

import { commonConfig, getCountryConfigFromLocale } from './config_hard';


//!IMPORTANT: THIS will be used if there is no .env variables in current env.


export const coreConfig = {
	name: process.env.REACT_APP_NAME,
	apiUrl: process.env.REACT_APP_API_URL,
	fastApi: process.env.REACT_APP_FAST_API,
	token: process.env.REACT_APP_TOKEN,
	language: process.env.REACT_APP_LANGUAGE,
	locale: process.env.REACT_APP_LOCALE,
	gtmId: process.env.REACT_APP_GTM_ID,
	geolocationApiDomain: process.env.REACT_APP_GEOLOCATION_API_DOMAIN,
	stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
	amazonStoreId: process.env.REACT_APP_AMAZON_STORE_ID,
	amazonMerchantId: process.env.REACT_APP_AMAZON_MERCHANT_ID,
	amazonPublicKeyId: process.env.REACT_APP_AMAZON_PUBLIC_KEY_ID,
	zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
	googleMerchantId: process.env.REACT_APP_GOOGLE_MERCHANT_ID,
	paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
};

// Export the final configuration
const config = {
	...commonConfig,
	...coreConfig,
	countryDefault: getCountryConfigFromLocale(coreConfig.locale),
};

export default config;
